import apiCaller from "./axios.service";

const baseUrl: string = "/v1/private/player";

export const updateLanguage = async <T>(
	player_language: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/updateLanguage`, { player_language });
};

export const getProfile = () => {
	return apiCaller(`${baseUrl}/getProfile`);
};

export const getGameList = async <T>({
	pageSize,
	current,
	sort,
}: {
	pageSize?: number;
	current?: number;
	sort?: any;
}): Promise<T> => {
	return apiCaller(`${baseUrl}/getGameList`, {
		pageSize,
		current,
		sort,
	});
};

export const generateGameUrl = async <T>(
	game_list_game_id: number,
	lobbyURL: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/generateGameUrl`, {
		game_list_game_id,
		lobbyURL,
	});
};
